import table from './table'

export default {
    id: 'Zahlungslauf #{id}',
    formProcesses: 'Formulare',
    subsidy_granted: 'Zuschuss',
    intended_use: 'Verwendungszweck',
    sum: 'Gesamtbetrag',
    payments: 'Zahlungen',

    organisationName: 'Verband',
    intendedUse: 'Verwendungszweck',
    year: 'Jahr',
    iban: 'IBAN',
    accountHolder: 'Kontoinhaber',
    comment: 'Interner Kommentar',
    percentage: 'Prozentsatz',
    saveSuccess: 'Zahlungslauf erfolgreich gespeichert',

    table,

    menu: {
        paymentRunBaseData: 'Daten',
        paymentRunPayments: 'Zahlungen'
    }
}