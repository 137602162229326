import general from './general/main'
import users from './users/main'
import organisations from './organisations/main'
import loginProviders from './loginProviders/main'

export default {
    selected: 'Selected',

    general,
    users,
    organisations,
    loginProviders,
}