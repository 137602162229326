import routerOptions0 from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}