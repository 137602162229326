export default {
    title: 'Vorgänge',
    freetext: 'Freitext',
    serial_number: 'Vorgangsnummer',
    year: 'Jahr',
    status: 'Status',
    id: 'ID',
    event: 'Veranstaltung',
    organisation: 'Organisation',
    fundingProgram: 'Förderprogramm',
    funding_program: 'Förderprogramm',
    period: 'Von - Bis',
    formProcesses: 'Formulare',
    subsidy_granted: 'Zuschuss',
    intended_use: 'Verwendungszweck',
    sum: 'Gesamtbetrag',
    payments: 'Zahlungen',
    documents: 'Dokumente',
    created_at: 'Erstellt am',

    qf_ownOnly: 'Nur eigene Vorgänge'
};