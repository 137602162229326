import categories from './categories/main'
import forms from './forms/main'
import fundingPrograms from './fundingPrograms/main'
import general from './general/main'
import loginProviders from './loginProviders/main'
import organisations from './organisations/main'
import organisationUsers from './organisationUsers/main'
import processes from './processes/main'
import regions from './regions/main'
import roles from './roles/main'
import statuses from './statuses/main'
import userOrganisations from './userOrganisations/main'
import users from './users/main'
import years from './years/main'

export default {
    selected: 'Ausgewählt',

    categories,
    forms,
    fundingPrograms,
    general,
    loginProviders,
    organisations,
    organisationUsers,
    processes,
    regions,
    roles,
    statuses,
    userOrganisations,
    users,
    years,
}