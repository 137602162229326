export default {
    home: 'Home',
    fundingPrograms: 'Förderprogramme',
    notifications: 'Benachrichtigungen',
    myProfile: 'Mein Profil',
    myOrganisation: 'Meine Organisation',
    login: 'Login',
    logout: 'Logout',
    service: 'Intern',
    dashboard: 'Dashboard',
    processes: 'Vorgänge',
    statistics: 'Statistik',
    payment: 'Zahlungen',
    users: 'Nutzer',
    organisations: 'Organisationen',
    basicData: 'Stammdaten',
    forms: 'Formulare',
    categories: 'Kategorien',
    statuses: 'Status',
    roles: 'Rollen',
    years: 'Jahre',
    mailTemplates: 'E-Mailvorlagen',
    ags: 'AGS',
    information: 'Informationen',
    settings: 'Einstellungen',
    jugendzaehlt: 'Jugend Zählt',
    regions: 'Kirchenbezirke',
    parishes: 'Kirchengemeinden',
    loginProviders: 'Login Methoden',
    paymentRuns: 'Zahlungsläufe',

    secret: 'Geheimnis',
    nothingToSeeHere: 'Hier gibt es nichts zu sehen 🙈',
    escapeRoom: '//farbcode Dungeon Escape! 🏰',
}
