import activityLog from './activityLog'
import address from './address'
import auth from './auth'
import categories from './categories'
import editor from './editor'
import files from './files'
import formProcesses from './formProcesses/main'
import forms from './forms'
import fundingPrograms from './fundingPrograms/main'
import globalSearch from './globalSearch'
import jugendzaehlt from "./jugendzaehlt";
import loginProviders from './loginProviders/main'
import mailTemplates from './mailTemplates/main'
import menu from './menu'
import notifications from './notifications'
import organisations from './organisations/main'
import organisationUsers from './organisationUsers/main'
import pageTitle from './pageTitle'
import parishes from './parishes/main'
import paymentRuns from './paymentRuns/main'
import payments from './payments/main'
import process from './process/main'
import processes from './processes/main'
import regionParishes from './regionParishes/main'
import regions from './regions/main'
import requestLog from './requestLog'
import roles from './roles/main'
import secret from './secret'
import settings from './settings'
import signature from './signature'
import statuses from './statuses/main'
import table from './table/main'
import testUsers from './testUsers/main'
import userOrganisations from './userOrganisations/main'
import users from './users/main'
import wiki from './wiki'
import years from './years'

export default {
    localeHint: 'Dies ist die deutsche Version der Website.',
    canNotAccessRoute: 'Sie haben keine Berechtigung, auf diese Seite zu gehen.',

    serverError: 'Es liegt serverseitig ein Fehler vor.',
    serverErrorSupport: 'Falls sich dieser Fehler wiederholt wenden Sie sich bitte an den Support.',

    invalidRequestData: 'Die Anfrage enthält ungültige Daten.',
    modelDoesNotExist: 'Die angeforderte Ressource existiert nicht.',
    unauthorizedRequest: 'Sie sind nicht berechtigt, diese Seite aufzurufen.',

    baseData: 'Daten',
    pleaseSelect: 'Bitte wählen...',

    resourceDoesNotExist: 'Der aufgerufene Eintrag existiert nicht, oder Sie haben keine Berechtigung diesen abzurufen.',
    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    abort: 'Abbrechen',
    delete: 'Löschen',
    back: 'Zurück',
    save: 'Speichern',
    discard: 'Verwerfen',
    error: 'Fehler',
    open: 'Öffnen',
    show: 'Anzeigen',
    more: 'Weiteres',
    change: 'Ändern',
    add: 'Hinzufügen',
    attach: 'Verknüpfen',
    detach: 'Verknüpfung lösen',
    welcome: 'Willkommen!',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger anzeigen',
    yes: 'Ja',
    no: 'Nein',
    name: 'Name',

    locales: {
        de: 'Deutsch', en: 'English'
    },

    activityLog,
    address,
    auth,
    categories,
    editor,
    files,
    formProcesses,
    forms,
    fundingPrograms,
    globalSearch,
    jugendzaehlt,
    loginProviders,
    mailTemplates,
    menu,
    notifications,
    organisations,
    organisationUsers,
    pageTitle,
    parishes,
    paymentRuns,
    payments,
    process,
    processes,
    regionParishes,
    regions,
    requestLog,
    roles,
    secret,
    settings,
    signature,
    statuses,
    table,
    testUsers,
    userOrganisations,
    users,
    wiki,
    years,
}

