export default {
    title: 'Ergebnis',
    actionsExecuted: 'Es wurde kein Ereignis festgehalten|Es wurde ein Ereignis festgehalten|Es wurden {count} Ereignisse festgehalten',

    type_default_action: "Keine Aktion ausgeführt|Eine Aktion ausgeführt|{count} Aktionen ausgeführt",
    type_user_deleted: "Kein Nutzer gelöscht|Ein Nutzer gelöscht|{count} Nutzer gelöscht",
    type_user_not_deletable: "Kein Nutzer konnte nicht gelöscht werden|Ein Nutzer konnte nicht gelöscht werden|{count} Nutzer konnten nicht gelöscht werden",

    type_tokens_generated: "Kein Token wurde erstellt|Token wurden erstellt|Token wurden erstellt"
}
