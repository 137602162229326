export default {
    title: 'Rechte',

    section: 'Bereich',
    type: 'Typ',
    allOrganisations: 'Gilt für alle Organisationen',
    limitation: 'Einschränkung',
    limitationHelpText: 'Darf das oben Ausgewählte nur auf eingeschränkte Daten zugreifen?',
    limitationDescription: 'Darf nur Anträge {section} von',

    sections: {
        eventData: 'Veranstaltungsdaten',
        personsData: 'Personendaten',
    },

    limitation_type: 'Typ',

    search: 'Suche {type}',

    organisation: 'Organisation',
    user: 'Benutzer',
    category: 'Kategorie',
    ags: 'AGS',

    addFundingProgramRight: 'Recht hinzufügen',
    editFundingProgramRight: 'Recht bearbeiten',
    saveFundingProgramRight: 'Recht speichern',
    copyFundingProgramRight: 'Recht kopieren',
    deleteFundingProgramRight: 'Recht löschen',
    deleteFundingProgramRightText: 'Möchtest du dieses Recht wirklich löschen?',

    savedSuccessfully: 'Recht wurde erfolgreich gespeichert.',
    errorWhileSaving: 'Das Recht konnte nicht gespeichert werden.',
    copiedSuccessfully: 'Recht wurde erfolgreich kopiert.',
    deletedSuccessfully: 'Recht wurde erfolgreich gelöscht.',
    errorWhileDeleting: 'Das Recht konnte nicht gelöscht werden.',
}