import menu from './menu';
import table from './table';

export default {
    reference_number: 'Kennnummer',
    parent_region_id: 'LK',
    name: 'Bezeichnung',
    token: 'Token',
    members_all: 'Gesamtzahl Glieder',
    members_youth: 'Glieder (6-26)',
    average_age: 'Durschnittsalter',
    location: 'Prälatur/Ort',
    jugendZaehltLink: 'Link zur Jugend zählt Seite',
    jugendZaehltLinkToken: 'Direkter Link mit Token',

    menu,
    table,
}