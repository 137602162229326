import amosWeb from './amosWeb'
import table from './table'

export default {
    deleteUserOrganisation: 'Verknüpfung lösen',
    deleteUserOrganisationText: 'Möchtest du den Nutzer wirklich von der Organisation lösen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteUserOrganisationSuccess: 'Verknüpfung wurde erfolgreich gelöst.',
    deleteUserOrganisationError: 'Die Verknüpfung konnte nicht gelöst werden.',
    editUserOrganisation: 'Verknüpfung bearbeiten',
    editUserOrganisationTitle: 'Verknüpfung {name} bearbeiten',
    editUserOrganisationSuccess: 'Verknüpfung wurde erfolgreich bearbeitet.',
    editUserOrganisationError: 'Die Verknüpfung konnte nicht bearbeitet werden.',
    saveUserOrganisationEdit: 'Verknüpfung speichern',

    amosWeb,
    table,
}