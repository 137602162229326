export default {
    title: 'Kirchengemeinden',
    name: 'Bezeichnung',
    kro: 'KRO',
    region_name: 'Kirchenbezirk',
    parish_type: 'Art',
    city: 'Ort',
    street: 'Straße',
    zip: 'PLZ',
    email: 'E-Mail',
    phone: 'Telefon',
    valid_report: 'Gültig',
    created_at: 'Ertsellt am',
    reference_number: 'Kennnummer',
    customName: 'Bezeichnung',
    parent_region_id: 'LK',
    token: 'Token',
    members_all: 'Glieder',
    members_youth: 'Glieder (6-26)',
    average_age: 'Alter ø',
    location: 'Prälatur',
    updated_at: 'Geändert am',

    parishes: 'Kirchengemeinden',
    customRegion: 'Kirchenbezirk',
    customAddress: 'Addresse',
    customCommunications: 'Kommunikationen'
}