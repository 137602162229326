export default {
    title: 'Bankverbindungen',

    designation: 'Bezeichnung',
    iban: 'IBAN',
    account_holder: 'Kontoinhaber',
    default: 'Standard Bankverbindung',

    addBankAccount: 'Neue Bankverbindung',
    addNewBankAccount: 'Neue Bankverbindung hinzufügen',
    editBankAccount: 'Bankverbindung bearbeiten',
    saveBankAccount: 'Bankverbindung speichern',
    selectBankAccount: 'Bankverbindung auswählen',
    deleteBankAccount: 'Bankverbindung löschen',
    deleteBankAccountText: 'Möchtest du diese Bankverbindung wirklich löschen?',
    deleteBankAccountNameText: 'Möchtest du die Bankverbindung "{name}" wirklich löschen?',

    successfullySaved: 'Bankverbindung wurde erfolgreich gespeichert.',
    successfullyDeleted: 'Bankverbindung wurde erfolgreich gelöscht.',
    couldNotBeSaved: 'Bankverbindung konnte nicht gespeichert werden.',
    couldNotBeDeleted: 'Bankverbindung konnte nicht gelöscht werden.'
}