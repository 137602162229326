export default {
    connection: 'amosWEB Verbindung',
    tenant_abbreviation: 'Organisationskürzel',
    tenant_abbreviation_help_text: 'Das Organisationskürzel ist bei "XXX.amosweb.de" das "XXX".',
    user: 'Benutzername',
    password: 'Passwort',
    token: 'Token',

    connect: 'Verbinden',
    connected: 'Verbunden',
    connectedWith: 'Verbunden mit',
    disconnect: 'amosWEB-Verbindung trennen',
    disconnected: 'Nicht verbunden',

    connectionSuccess: 'Erfolgreich mit amosWEB verbunden.',
    couldNotConnect: 'Verbindung konnte nicht hergestellt werden.',
    disconnectionSuccess: 'Verbindung wurde erfolgreich getrennt.',
    couldNotDisconnect: 'Verbindung konnte nicht getrennt werden.'
}