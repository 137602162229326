import table from './table'

export default {
    deleteOrganisationUser: 'Verknüpfung lösen',
    deleteOrganisationUserText: 'Möchtest du die Organisation wirklich von dem Nutzer lösen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrganisationUserSuccess: 'Verknüpfung wurde erfolgreich gelöst.',
    deleteOrganisationUserError: 'Die Verknüpfung konnte nicht gelöst werden.',
    editOrganisationUser: 'Verknüpfung bearbeiten',
    editOrganisationUserTitle: 'Verknüpfung {name} bearbeiten',
    editOrganisationUserSuccess: 'Verknüpfung wurde erfolgreich bearbeitet.',
    editOrganisationUserError: 'Die Verknüpfung konnte nicht bearbeitet werden.',
    saveOrganisationUserEdit: 'Verknüpfung speichern',

    table,
}