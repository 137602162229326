export default{
    id: 'Id',
    name: 'Name',
    organisation_id: 'Organisations Id',
    creator_user_id: 'Ersteller Id',
    year_id: 'Jahres Id',
    funding_program_id: 'Förderprogramm Id',
    status_id: 'Status Id',

    // Status
    key: 'Schlüssel',

    // Unterschrift
    signatory: 'Unterzeichner/-in',
    form: 'Formular',

    // Veranstaltung
    event_id: 'Veranstaltungs Id',
    start_date: 'Startdatum',
    end_date: 'Enddatum',
    duration: 'Dauer',
    street: 'Straße',
    house_number: 'Hausnummer',
    zipcode: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',

    // Vorgang
    process_id: 'Vorgangs Id',
    serial_number: 'Vorgangsnummer',
    subsidy: 'Zuschuss',
    total_cost: 'Gesamtkosten',
    subsidy_requested: 'Beantragt',
    subsidy_due: 'Zustehend',
    subsidy_granted: 'Bewilligt',
    subsidy_granted_at: 'Bewilligt am',
    subsidy_received_too_much: 'Zuviel erstattet',
    persons_male: 'Männliche Personen',
    persons_female: 'Weibliche Personen',
    persons_other: 'Andere Personen',
    persons_total: 'Personen insgesamt',
    eligible_persons_male: 'Anrechenbare männliche Personen',
    eligible_persons_female: 'Anrechenbare weibliche Personen',
    eligible_persons_other: 'Anrechenbare andere Personen',
    eligible_persons_total: 'Anrechenbare Personen insgesamt',
    eligible_days: 'Tage anrechenbar',
    eligible_hours: 'Stunden anrechenbar',

    // Formular
    form_process_id: 'Formular Id',
    bank_account_id: 'Bank Account Id',
    formable_id: 'Formable',
    formable_type: 'Formulartyp',
    data: 'Daten',

    // Programmnachweis
    date: 'Datum',
    start: "Start",
    end: "Ende",
    hours: "Stunden",
    description: "Beschreibung",

    // Personen
    event_person_id: 'Person Id',
    function: 'Funktion',
    gender: 'Geschlecht',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtstag',
    participation_begin: 'Beginn der Teilnahme',
    participation_end: 'Ende der Teilnahme',
    participation_duration: 'Dauer der Teilnahme',

    // Dokument
    document_id: 'Dokument Id',
    upload_box_identifier: 'Upload Box',
    visibility_section: 'Sichtbarkeit'
}