export default {
    title: 'Programmnachweis',
    date: 'Datum',
    dayOfWeek: 'Wochentag',
    workUnitsListing: 'Dauer der einzelnen Arbeitseinheiten:',
    addWorkUnit: 'Neue Arbeitseinheit anlegen',
    addDay: 'Neuen Tag hinzufügen',
    listing: {
        start: 'Start',
        end: 'Ende',
        hours: 'Stunden',
        description: 'Programm mit genauen Angaben über die behandelten Themen'
    },
    sumWorkUnits: 'Summe der Arbeitseinheiten',
    delete: {
        title: 'Datum: {date} löschen',
        text: 'Durch diese Aktion werden alle zugehörigen Arbeitseinheiten gelöscht'
    },
    dateAlreadyExists: 'Datum existiert bereits',
    noMoreDatesAvailable: 'Es gibt kein weiteres verfügbares Datum zwischen dem Start- und Enddatum des Events.',
    changeCausedErrors: 'Durch die Aktion sind Fehler im Programmnachweis entstanden.',
    showListing: 'Liste anzeigen',
    hideListing: 'Liste verbergen'
}