<template>
    <NuxtLayout>
        <NuxtPage />
        <Teleport
            :disabled="teleportNotificationArea === false"
            :to="teleportNotificationArea ? '#notificationAreaRoot' : null">
            <NotificationArea position="top-right" />
        </Teleport>
    </NuxtLayout>
</template>

<script setup>
import {usePageTitle} from "@/composables/usePageTitle";

const {t, te} = useI18n()
const route = useRoute()
const {public: publicConfig} = useRuntimeConfig()
const pageTitle = usePageTitle()

const title = computed(() => {
    // custom title from pageTitle, has to be set through global state in order to be reactive (https://github.com/nuxt/nuxt/issues/13463)
    if (pageTitle.value) return pageTitle.value
    // global default title from translation file
    if (route.name) return te(`pageTitle.${route.name}`) ? t(`pageTitle.${route.name}`) : false
})

useHead(() => ({
    title: (title.value) ? `${publicConfig.appName} - ${title.value}` : publicConfig.appName
}))

const teleportNotificationArea = useState('teleportNotificationArea', () => false)
</script>