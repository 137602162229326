import menu from './menu'
import table from './table'

export default {
    role: 'Rolle',
    name: 'Identifikator',
    name_de: 'Name',

    saveSuccess: 'Rolle wurde erfolgreich gespeichert.',
    deleteRole: 'Rolle löschen',
    deleteRoleText: 'Möchtest du diese Rolle wirklich löschen? Die Verknüpfungen von Nutzern mit Organisationen mit dieser Rolle werden dann auch gelöscht.',
    deleteRoleSuccess: 'Rolle wurde erfolgreich gelöscht.',
    deleteRoleError: 'Die Rolle konnte nicht gelöscht werden.',

    menu,
    table,
}