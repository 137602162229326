export default {
    title: 'Kategorien',
    attachedCategories: 'Verknüpfte Kategorien',

    table: {
        name: 'Name'
    },
    edit: {
        title: "{category} bearbeiten",
        save: "Kategorie speichern",
        saveCategorySuccess: 'Die Kategorie wurde erfolgreich gespeichert.',
        saveCategoryError: 'Die Kategorie konnte nicht gespeichert werden.'
    },

    name: 'Name',
    parentCategory: 'Übergeordnete Kategorie',
    inheritable: 'Kategorie vererbbar',
    category: 'Kategorie',

    attachCategory: 'Kategorie verknüpfen',

    search: 'Suche',
    searchPlaceholder: 'Suche nach Kategorie',

    saveSuccess: 'Kategorie wurde erfolgreich gespeichert.',
    deleteCategory: 'Kategorie löschen',
    deleteCategoryText: 'Möchtest du diese Kategorie wirklich löschen?',
    deleteCategorySuccess: 'Kategorie wurde erfolgreich gelöscht.',
    deleteCategoryError: 'Die Kategorie konnte nicht gelöscht werden.',
}