import { default as indexfcHtiM9rTyMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/auth/index.vue?macro=true";
import { default as indexXEV0tXsebDMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_935I4XndwzAyMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/formProcess/[id].vue?macro=true";
import { default as _91id_9372YV7g1eH0Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/formProcess/success/[id].vue?macro=true";
import { default as indexsm3UnkoHjrMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/index.vue?macro=true";
import { default as _91region_93yNYQGUpcGbMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/jugend-zaehlt/[region].vue?macro=true";
import { default as organisationBaseData3x0AzesqnWMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as organisationsd1jBiSapnsMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/organisations.vue?macro=true";
import { default as indexmoRTYxp3bTMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/processes/index.vue?macro=true";
import { default as processesyYr28JcdS2Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/processes.vue?macro=true";
import { default as secretqdUcdnpFepMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/secret.vue?macro=true";
import { default as agsjuj1j5RvvtMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/ags.vue?macro=true";
import { default as indexRjIPlghAJ4Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/categories/index.vue?macro=true";
import { default as formBaseDatazs8IOQwAG9Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id]/formBaseData.vue?macro=true";
import { default as formClassQYseQf4PC4Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id]/formClass.vue?macro=true";
import { default as _91id_93QjWb7L76UgMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id].vue?macro=true";
import { default as indexd3xLY04vVCMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/index.vue?macro=true";
import { default as formsNxAAtkHL4EMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms.vue?macro=true";
import { default as fundingProgramBaseData7GpJgcA4qaMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramBaseData.vue?macro=true";
import { default as fundingProgramProcessFieldsxJPtOVnlOfMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramProcessFields.vue?macro=true";
import { default as fundingProgramRightsUX1ZFFme3MMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramRights.vue?macro=true";
import { default as _91id_93WdiHB6LcMCMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id].vue?macro=true";
import { default as indexoEznorol2uMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/index.vue?macro=true";
import { default as _91_46_46_46slug_93mX1w1zv3AmMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexrOjFucuUSGMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/mailTemplates/index.vue?macro=true";
import { default as roleBaseDataHxXYUB3RZUMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/[id]/roleBaseData.vue?macro=true";
import { default as _91id_93uvJHBeEVdXMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/[id].vue?macro=true";
import { default as indexYnx21SMLRFMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/index.vue?macro=true";
import { default as statistics0PhBrXAbrfMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statistics.vue?macro=true";
import { default as statusActivities5AbPHFTezBMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id]/statusActivities.vue?macro=true";
import { default as statusBaseDataokfygJloVnMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id]/statusBaseData.vue?macro=true";
import { default as _91id_937jqqYCzX5rMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id].vue?macro=true";
import { default as indexzFFZ3DJb03Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/index.vue?macro=true";
import { default as yearBaseDatai8fDqoUCpDMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id]/yearBaseData.vue?macro=true";
import { default as yearDataRMXVT5S6xDMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id]/yearData.vue?macro=true";
import { default as _91id_93y4EUnYC63sMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id].vue?macro=true";
import { default as indexykfZucsGO7Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/index.vue?macro=true";
import { default as _91id_93d8t3x0g3ndMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/formProcesses/[id].vue?macro=true";
import { default as indexPkoYCQ9gCkMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/index.vue?macro=true";
import { default as parishAgeGroupsEwLpCSJdpoMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id]/parishAgeGroups.vue?macro=true";
import { default as parishBaseDatasiqNezCE3eMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id]/parishBaseData.vue?macro=true";
import { default as _91id_93ymSb2nubkNMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id].vue?macro=true";
import { default as indexH2VxnDbu2FMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/index.vue?macro=true";
import { default as parishese5XL2b8JVwMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes.vue?macro=true";
import { default as regionActivitiesYhRsRpnuGMMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionActivities.vue?macro=true";
import { default as regionAgeGroupshhLeOGdouiMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionAgeGroups.vue?macro=true";
import { default as regionBaseDataH1t2zZqhKEMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionBaseData.vue?macro=true";
import { default as regionDiaconiaspNI6OIX0Q8Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionDiaconias.vue?macro=true";
import { default as regionParishesM2O3IuOT6IMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionParishes.vue?macro=true";
import { default as _91id_93p75fvNn4P5Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id].vue?macro=true";
import { default as indexTpZBKfRAFGMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/index.vue?macro=true";
import { default as regions2P63COFZ7tMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions.vue?macro=true";
import { default as paymentRunBaseDataK7Q3cxqXroMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id]/paymentRunBaseData.vue?macro=true";
import { default as paymentRunPaymentsSDtYBHUkyJMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id]/paymentRunPayments.vue?macro=true";
import { default as _91id_93oMNSAp38FoMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id].vue?macro=true";
import { default as indexvt9iuGVKBMMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/index.vue?macro=true";
import { default as paymentsuWFETFPyNfMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments.vue?macro=true";
import { default as processActivitiescCF9u65YAMMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id]/processActivities.vue?macro=true";
import { default as processBaseData4eZdHa5l6nMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id]/processBaseData.vue?macro=true";
import { default as _91id_93EZcqE5KZCdMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id].vue?macro=true";
import { default as indexbM3cZ0ERH1Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/index.vue?macro=true";
import { default as _91id_93YRmgBfkmZzMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders/[id].vue?macro=true";
import { default as indexSbSEWcn8PqMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders/index.vue?macro=true";
import { default as loginProvidersLQNzQAGscqMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders.vue?macro=true";
import { default as organisationActivities7JdwJBJw9pMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationActivities.vue?macro=true";
import { default as organisationBaseDataJJ4ZgXupMJMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as organisationCategoriesJ9HV81jEC8Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationCategories.vue?macro=true";
import { default as organisationTransferAgreementLCuWyW5uLUMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationTransferAgreement.vue?macro=true";
import { default as organisationUsersfFU8gBvpXHMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationUsers.vue?macro=true";
import { default as _91id_93El6xpifR1cMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id].vue?macro=true";
import { default as indexXtZme6E8P1Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/index.vue?macro=true";
import { default as organisations1qS3kn6fuBMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations.vue?macro=true";
import { default as userActivitieshegg5SRwTNMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataAtdT6yREz8Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id]/userBaseData.vue?macro=true";
import { default as _91id_93OEPcmnqQoKMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id].vue?macro=true";
import { default as indexS5pskfOujSMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/index.vue?macro=true";
import { default as usersA9MK5ieo9JMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users.vue?macro=true";
import { default as statisticskDAi8o2zMnMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/statistics.vue?macro=true";
import { default as servicek20rN0Ya6VMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service.vue?macro=true";
import { default as language7h6s9g5aL6Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/language.vue?macro=true";
import { default as organisationActivitiesOuqb6fXEFJMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationActivities.vue?macro=true";
import { default as organisationBaseDataCGW5Cz3BEVMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as organisationCategoriesU4PO70BCt3Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationCategories.vue?macro=true";
import { default as organisationTransferAgreement0ZwHjjdPlrMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationTransferAgreement.vue?macro=true";
import { default as organisationUsersXygTAHYjgwMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationUsers.vue?macro=true";
import { default as _91id_93y8Ql23YjEbMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id].vue?macro=true";
import { default as userActivitiesGqlfKaqpsBMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatag8V4ssggowMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id]/userBaseData.vue?macro=true";
import { default as _91id_93JWZgMyWOK8Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id].vue?macro=true";
import { default as settings7xjyLB9SR9Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings.vue?macro=true";
import { default as signatureDcCpwh5Yb6Meta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/signature.vue?macro=true";
import { default as statisticsZ3a0xuJXjEMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/statistics.vue?macro=true";
import { default as _91_46_46_46slug_93i3I1Mi2uGzMeta } from "/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexfcHtiM9rTyMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexXEV0tXsebDMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "formProcess-id",
    path: "/formProcess/:id()",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/formProcess/[id].vue").then(m => m.default || m)
  },
  {
    name: "formProcess-success-id",
    path: "/formProcess/success/:id()",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/formProcess/success/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexsm3UnkoHjrMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jugend-zaehlt-region",
    path: "/jugend-zaehlt/:region()",
    meta: _91region_93yNYQGUpcGbMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/jugend-zaehlt/[region].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    meta: organisationsd1jBiSapnsMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: ":id()/organisationBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: processesyYr28JcdS2Meta?.name,
    path: "/processes",
    meta: processesyYr28JcdS2Meta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/processes.vue").then(m => m.default || m),
    children: [
  {
    name: "processes",
    path: "",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/processes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: servicek20rN0Ya6VMeta?.name,
    path: "/service",
    meta: servicek20rN0Ya6VMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service.vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-ags",
    path: "basicData/ags",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/ags.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-categories",
    path: "basicData/categories",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: formsNxAAtkHL4EMeta?.name,
    path: "basicData/forms",
    meta: formsNxAAtkHL4EMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms.vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-forms-id",
    path: ":id()",
    meta: _91id_93QjWb7L76UgMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-forms-id-formBaseData",
    path: "formBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id]/formBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-forms-id-formClass",
    path: "formClass",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/[id]/formClass.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-forms",
    path: "",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/forms/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-fundingPrograms-id",
    path: "basicData/fundingPrograms/:id()",
    meta: _91id_93WdiHB6LcMCMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-fundingPrograms-id-fundingProgramBaseData",
    path: "fundingProgramBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-fundingPrograms-id-fundingProgramProcessFields",
    path: "fundingProgramProcessFields",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramProcessFields.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-fundingPrograms-id-fundingProgramRights",
    path: "fundingProgramRights",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramRights.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-fundingPrograms",
    path: "basicData/fundingPrograms",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/fundingPrograms/index.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-mailTemplates-name-slug",
    path: "basicData/mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93mX1w1zv3AmMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-mailTemplates",
    path: "basicData/mailTemplates",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/mailTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-roles-id",
    path: "basicData/roles/:id()",
    meta: _91id_93uvJHBeEVdXMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-roles-id-roleBaseData",
    path: "roleBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/[id]/roleBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-roles",
    path: "basicData/roles",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-statistics",
    path: "basicData/statistics",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statistics.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-statuses-id",
    path: "basicData/statuses/:id()",
    meta: _91id_937jqqYCzX5rMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-statuses-id-statusActivities",
    path: "statusActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id]/statusActivities.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-statuses-id-statusBaseData",
    path: "statusBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/[id]/statusBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-statuses",
    path: "basicData/statuses",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/statuses/index.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-years-id",
    path: "basicData/years/:id()",
    meta: _91id_93y4EUnYC63sMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-basicData-years-id-yearBaseData",
    path: "yearBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id]/yearBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-basicData-years-id-yearData",
    path: "yearData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/[id]/yearData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-basicData-years",
    path: "basicData/years",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/basicData/years/index.vue").then(m => m.default || m)
  },
  {
    name: "service-formProcesses-id",
    path: "formProcesses/:id()",
    meta: _91id_93d8t3x0g3ndMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/formProcesses/[id].vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: parishese5XL2b8JVwMeta?.name,
    path: "jugendzaehlt/parishes",
    meta: parishese5XL2b8JVwMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes.vue").then(m => m.default || m),
    children: [
  {
    name: "service-jugendzaehlt-parishes-id",
    path: ":id()",
    meta: _91id_93ymSb2nubkNMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-jugendzaehlt-parishes-id-parishAgeGroups",
    path: "parishAgeGroups",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id]/parishAgeGroups.vue").then(m => m.default || m)
  },
  {
    name: "service-jugendzaehlt-parishes-id-parishBaseData",
    path: "parishBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/[id]/parishBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-jugendzaehlt-parishes",
    path: "",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/parishes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: regions2P63COFZ7tMeta?.name,
    path: "jugendzaehlt/regions",
    meta: regions2P63COFZ7tMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions.vue").then(m => m.default || m),
    children: [
  {
    name: "service-jugendzaehlt-regions-id",
    path: ":id()",
    meta: _91id_93p75fvNn4P5Meta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-jugendzaehlt-regions-id-regionActivities",
    path: "regionActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionActivities.vue").then(m => m.default || m)
  },
  {
    name: "service-jugendzaehlt-regions-id-regionAgeGroups",
    path: "regionAgeGroups",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionAgeGroups.vue").then(m => m.default || m)
  },
  {
    name: "service-jugendzaehlt-regions-id-regionBaseData",
    path: "regionBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-jugendzaehlt-regions-id-regionDiaconias",
    path: "regionDiaconias",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionDiaconias.vue").then(m => m.default || m)
  },
  {
    name: "service-jugendzaehlt-regions-id-regionParishes",
    path: "regionParishes",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/[id]/regionParishes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-jugendzaehlt-regions",
    path: "",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/jugendzaehlt/regions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-payments",
    path: "payments",
    meta: paymentsuWFETFPyNfMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments.vue").then(m => m.default || m),
    children: [
  {
    name: "service-payments-paymentRuns-id",
    path: "paymentRuns/:id()",
    meta: _91id_93oMNSAp38FoMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-payments-paymentRuns-id-paymentRunBaseData",
    path: "paymentRunBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id]/paymentRunBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-payments-paymentRuns-id-paymentRunPayments",
    path: "paymentRunPayments",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/[id]/paymentRunPayments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-payments-paymentRuns",
    path: "paymentRuns",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/payments/paymentRuns/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-processes-id",
    path: "processes/:id()",
    meta: _91id_93EZcqE5KZCdMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-processes-id-processActivities",
    path: "processActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id]/processActivities.vue").then(m => m.default || m)
  },
  {
    name: "service-processes-id-processBaseData",
    path: "processBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/[id]/processBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-processes",
    path: "processes",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/processes/index.vue").then(m => m.default || m)
  },
  {
    name: loginProvidersLQNzQAGscqMeta?.name,
    path: "settings/loginProviders",
    meta: loginProvidersLQNzQAGscqMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders.vue").then(m => m.default || m),
    children: [
  {
    name: "service-settings-loginProviders-id",
    path: ":id()",
    meta: _91id_93YRmgBfkmZzMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "service-settings-loginProviders",
    path: "",
    meta: indexSbSEWcn8PqMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/loginProviders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: organisations1qS3kn6fuBMeta?.name,
    path: "settings/organisations",
    meta: organisations1qS3kn6fuBMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "service-settings-organisations-id",
    path: ":id()",
    meta: _91id_93El6xpifR1cMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-settings-organisations-id-organisationActivities",
    path: "organisationActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationActivities.vue").then(m => m.default || m)
  },
  {
    name: "service-settings-organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  },
  {
    name: "service-settings-organisations-id-organisationCategories",
    path: "organisationCategories",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationCategories.vue").then(m => m.default || m)
  },
  {
    name: "service-settings-organisations-id-organisationTransferAgreement",
    path: "organisationTransferAgreement",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationTransferAgreement.vue").then(m => m.default || m)
  },
  {
    name: "service-settings-organisations-id-organisationUsers",
    path: "organisationUsers",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/[id]/organisationUsers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-settings-organisations",
    path: "",
    meta: indexXtZme6E8P1Meta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersA9MK5ieo9JMeta?.name,
    path: "settings/users",
    meta: usersA9MK5ieo9JMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users.vue").then(m => m.default || m),
    children: [
  {
    name: "service-settings-users-id",
    path: ":id()",
    meta: _91id_93OEPcmnqQoKMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "service-settings-users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "service-settings-users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-settings-users",
    path: "",
    meta: indexS5pskfOujSMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/settings/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "service-statistics",
    path: "statistics",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/service/statistics.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisations-id",
    path: "organisations/:id()",
    meta: _91id_93y8Ql23YjEbMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "settings-organisations-id-organisationActivities",
    path: "organisationActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationActivities.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisations-id-organisationBaseData",
    path: "organisationBaseData",
    meta: organisationBaseDataCGW5Cz3BEVMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisations-id-organisationCategories",
    path: "organisationCategories",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationCategories.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisations-id-organisationTransferAgreement",
    path: "organisationTransferAgreement",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationTransferAgreement.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisations-id-organisationUsers",
    path: "organisationUsers",
    meta: organisationUsersXygTAHYjgwMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/organisations/[id]/organisationUsers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-users-id",
    path: "users/:id()",
    meta: _91id_93JWZgMyWOK8Meta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "settings-users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "signature",
    path: "/signature",
    meta: signatureDcCpwh5Yb6Meta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/signature.vue").then(m => m.default || m)
  },
  {
    name: "statistics",
    path: "/statistics",
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93i3I1Mi2uGzMeta || {},
    component: () => import("/home/forge/develop24.oase-bw.de/releases/20240912144532/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]