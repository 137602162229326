import table from './table'

export default {
    subject: 'Betreff',
    subjectPlaceholder: 'Betreff...',
    body: 'Inhalt',
    bodyPlaceholder: 'Inhalt der E-Mail...',
    updateSuccess: 'Die E-Mail-Vorlage wurde erfolgreich aktualisiert.',
    de: 'Deutsch',
    en: 'Englisch',

    table,
}