import {defineStore} from 'pinia'

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        isSidebarOpen: false,
        wikiSidebarOpen: false,
        isSubheaderOpen: false,
        currentModule: null,
        isBottomBannerExpanded: false,
    }),

    actions: {
        setSidebarOpen(isSidebarOpen) {
            this.isSidebarOpen = isSidebarOpen
        },
        setWikiSidebarOpen(isWikiSidebarOpen) {
            this.wikiSidebarOpen = isWikiSidebarOpen
        },
        setCurrentModule(currentModule) {
            this.currentModule = currentModule
        },
        setBottomBannerExpanded(isBottomBannerExpanded) {
            this.isBottomBannerExpanded = isBottomBannerExpanded
        }
    },
})
