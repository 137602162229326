import table from './table'
import history from './history'

export default {
    saveSuccess: 'Vorgang wurde erfolgreich gespeichert.',
    deleteProcess: 'Vorgang löschen',
    deleteProcessNumberText: 'Möchtest du den Vorgang {number} wirklich löschen?',
    deleteProcessText: 'Möchtest du diesen Vorgang wirklich löschen?',
    deleteProcessSuccess: 'Vorgang wurde erfolgreich gelöscht.',
    deleteProcessError: 'Der Vorgang konnte nicht gelöscht werden.',

    processId: 'Vorgang {id}',
    event: 'Veranstaltung',
    subsidy: 'Zuschuss',
    formProcess: 'Formular',
    formProcesses: 'Formulare',
    formProcessesCount: 'Keine Formulare|{count} Formular|{count} Formulare',
    noDocumentCouldBeExported: 'Es konnte kein Dokument zur Ansicht gefunden oder erzeugt werden.',
    exportError: 'Fehlende Vorlagen',
    additional: 'Weitere Informationen',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen in diesem Vorgang. Möchten Sie die Seite wirklich verlassen?',

    applicant: 'Antragsteller',
    creatorUser: 'Ansprechperson',
    callOrganisation: 'Organisation aufrufen',
    mailCreatorUser: 'E-Mail an Ansprechperson',
    bankAccount: 'Bankverbindung',
    noOrganisation: 'Kein Antragsteller',
    noCreatorUser: 'Keine Ansprechperson',
    noBankAccount: 'Keine Bankverbindung',

    startDate: 'Startdatum',
    endDate: 'Enddatum',
    location: 'Ort',
    title: 'Bezeichnung',
    daysTotal: 'Tage',
    totalDays: 'Tage',
    daysEligible: 'Anrechenbar',
    eligibleHours: 'Lehrgangsstunden',
    eligibleHoursRate: 'Faktor Lehrg.',
    cateredDays: 'Verpflegungstage',
    subsidyRate: 'Faktor Zuschuss',
    persons: 'Personen',
    eligible: 'Anrechenbar',
    personsTotal: 'Personen',
    personsMale: 'Personen männlich',
    personsFemale: 'Personen weiblich',
    personsOther: 'Personen divers',
    eligiblePersons: 'Anrechenbare Personen',
    eligibleTotal: 'Anrechenbar',
    eligibleMale: 'Anrechenbare männlich',
    eligibleFemale: 'Anrechenbare weiblich',
    eligibleOther: 'Anrechenbare divers',
    eligiblePersonsTotal: 'Anrechenbar',
    eligiblePersonsMale: 'Anrechenbare männlich',
    eligiblePersonsFemale: 'Anrechenbare weiblich',
    eligiblePersonsOther: 'Anrechenbare divers',

    eligibleDays: 'Anrechenbare Tage',

    totalCost: 'Gesamtkosten',
    subsidyRequested: 'Beantragt',
    subsidyGranted: 'Bewilligt',
    subsidyGrantedAt: 'Bewilligt am',
    subsidyDue: 'Zustehend',
    subsidyReceivedTooMuch: 'Zuviel erstattet',

    fileReferenceNumber: 'Aktenzeichen',
    cashReferenceNumber: 'Kassenzeichen',
    comment: 'Anmerkungen',

    menu: {
        processBaseData: 'Vorgang',
        processActivities: 'Historie'
    },

    table,
    history
}