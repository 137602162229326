import table from './table'

export default {
    id: 'Zahlungslauf #{id}',
    table,

    menu: {
        paymentRunBaseData: 'Daten',
        paymentRunPayments: 'Zahlungen'
    }
}