export default {
    address: 'Adresse',
    zipcode: 'Postleitzahl',
    city: 'Stadt',
    street: 'Straße',
    houseNumber: 'Hausnummer',
    country: 'Land',
    customAddress: 'Ausländisch oder manuell',
    searchAddress: 'Adresssuche DE',
    searchPlaceholder: 'Suche nach Postleitzahl, Stadt, Straße',
    searchZipcode: 'Suche nach Postleitzahl',
    searchCity: 'Suche nach Stadt',
    noSearchResults: 'Es wurde kein Eintrag zu dieser Suche gefunden. Möglicherweise wurde Ihre Straße noch nicht erfasst. Klicken Sie hier, um die Adresse manuell einzugeben.',
    selectExistingStreet: 'Existierende Straße wählen'
}