export default {
    intro: "Intro",
    introText: "Bitte nicht zu ernst nehmen, ich hab viel zu viele Ideen für weitere Räume und Rätsel. Fürs erste sollte das zum Testen aber reichen 😅",
    playerName: "Spielername",
    finish: "Ende des Spiels!",
    winMessage: "Du hast gewonnen!",
    stats: "Stats",

    stories: {
        dungeon1: 'Ihr findet euch in einem dunklen Raum wieder. Ihr könnt nur wenig erkennen, aber es scheint, als ob ihr in einem Verlies gefangen seid. Ihr müsst einen Weg finden, um zu entkommen!',
        bossBattle: 'Das ist der letzte Raum. Ihr seid dem Boss gegenüber. Ihr müsst ihn besiegen, um zu entkommen!',
    }
}