export default {
    id: 'ID',
    title: 'Organisationen',
    name: 'Name',
    parent_organisation_id: 'Übergeordnete Organisation',
    type: 'Typ',
    created_at: 'Erstellt am',
    active: 'Aktiv',
    isActive: 'Aktiv',
    isInactive: 'Inaktiv',
    categories: 'Kategorien',
    category: 'Kategorie',
}
