import {BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import {Bar} from 'vue-chartjs'
import annotationPlugin from 'chartjs-plugin-annotation';


export default defineNuxtPlugin((nuxtApp) => {
    Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
    Chart.register(annotationPlugin);

    nuxtApp.vueApp.component("BarChart", Bar)
})