import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTableFilterBoolean, LazyTableFilterCheckbox, LazyTableFilterNewFilterTemplate, LazyTableFilterNumeric, LazyTableFilterSearchSelect, LazyTableFilterSelect, LazyTableFilterString, LazyTableToolCategoriesCreate, LazyTableToolFormsCreate, LazyTableToolFundingProgramsCreate, LazyTableToolGeneralExport, LazyTableToolLoginProvidersCreate, LazyTableToolOrganisationUsersCreate, LazyTableToolOrganisationsAttachCategory, LazyTableToolOrganisationsCreate, LazyTableToolOrganisationsToggle, LazyTableToolProcessesPaymentRuns, LazyTableToolRegionsExport, LazyTableToolRegionsTokens, LazyTableToolRolesCreate, LazyTableToolStatusesCreate, LazyTableToolUserOrganisationsCreate, LazyTableToolUsersCreate, LazyTableToolUsersDelete, LazyTableToolYearsCreate, LazyBasicHeading, LazyBasicText, LazySubheading, LazyPostLoader, LazyKJPKT, LazyKJPPW, LazyKJPRM, LazyLJPPBC, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["TableFilterBoolean", LazyTableFilterBoolean],
["TableFilterCheckbox", LazyTableFilterCheckbox],
["TableFilterNewFilterTemplate", LazyTableFilterNewFilterTemplate],
["TableFilterNumeric", LazyTableFilterNumeric],
["TableFilterSearchSelect", LazyTableFilterSearchSelect],
["TableFilterSelect", LazyTableFilterSelect],
["TableFilterString", LazyTableFilterString],
["TableToolCategoriesCreate", LazyTableToolCategoriesCreate],
["TableToolFormsCreate", LazyTableToolFormsCreate],
["TableToolFundingProgramsCreate", LazyTableToolFundingProgramsCreate],
["TableToolGeneralExport", LazyTableToolGeneralExport],
["TableToolLoginProvidersCreate", LazyTableToolLoginProvidersCreate],
["TableToolOrganisationUsersCreate", LazyTableToolOrganisationUsersCreate],
["TableToolOrganisationsAttachCategory", LazyTableToolOrganisationsAttachCategory],
["TableToolOrganisationsCreate", LazyTableToolOrganisationsCreate],
["TableToolOrganisationsToggle", LazyTableToolOrganisationsToggle],
["TableToolProcessesPaymentRuns", LazyTableToolProcessesPaymentRuns],
["TableToolRegionsExport", LazyTableToolRegionsExport],
["TableToolRegionsTokens", LazyTableToolRegionsTokens],
["TableToolRolesCreate", LazyTableToolRolesCreate],
["TableToolStatusesCreate", LazyTableToolStatusesCreate],
["TableToolUserOrganisationsCreate", LazyTableToolUserOrganisationsCreate],
["TableToolUsersCreate", LazyTableToolUsersCreate],
["TableToolUsersDelete", LazyTableToolUsersDelete],
["TableToolYearsCreate", LazyTableToolYearsCreate],
["BasicHeading", LazyBasicHeading],
["BasicText", LazyBasicText],
["Subheading", LazySubheading],
["PostLoader", LazyPostLoader],
["KJPKT", LazyKJPKT],
["KJPPW", LazyKJPPW],
["KJPRM", LazyKJPRM],
["LJPPBC", LazyLJPPBC],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
