export default {
    title: 'Nutzer erstellen',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    organisation: 'Organisation',
    role: 'Rolle',
    createHelpText: 'Der Nutzer wird mit einem zufällig generierten Passwort angelegt. Dieses kann über die "Passwort zurücksetzen" Funktion geändert werden.',

    createUser: 'Nutzer erstellen',
    createAndEditUser: 'Erstellen und weiter bearbeiten',
    createUserSuccess: 'Der Nutzer wurde erfolgreich erstellt.',
    createUserError: 'Der Nutzer konnte nicht erstellt werden.'
}