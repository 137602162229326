export default {
    title: 'Förderprogramm erstellen',
    number: 'Nummer',
    name: 'Name',
    shortName: 'Kurzname',
    description: 'Beschreibung',
    isActive: 'aktivieren',
    parentFundingProgram: 'Übergeordnetes Förderprogramm',
    createFundingProgram: 'Förderprogramm erstellen',
    createAndEditFundingProgram: 'Erstellen und weiter bearbeiten',
    createFundingProgramSuccess: 'Das Förderprogramm wurde erfolgreich erstellt.',
    createFundingProgramError: 'Das Förderprogramm konnte nicht erstellt werden.'
}