import rights from './rights'

export default {
    fundingProgramId: 'Förderprogramm {id}',

    number: 'Nummer',
    name: 'Name',
    shortName: 'Kurzname',
    description: 'Beschreibung',
    parentFundingProgram: 'Übergeordnetes Förderprogramm',
    noParentFundingProgram: 'Kein übergeordnetes Förderprogramm',
    isActive: 'Aktiv',
    manualHours: 'Lehrgangsstunden manuell erfassen',

    selectParentFundingProgramWarning: 'Warnung: Wenn Sie ein übergeordnetes Förderprogramm auswählen, werden alle diesem Programm zugeordneten Programme losgelöst und Verknüpfungen aufgehoben. Dies kann nicht rückgängig gemacht werden. Anzahl der untergeordneten Programme: {count}',

    forms: 'Formulare',

    slideOver: {
        description: 'Wählen Sie ein Formular aus, um einen neuen Vorgang zu erstellen.',
        search: 'Suche',
        searchPlaceholder: 'Suche nach Förderprogrammen'
    },

    savedSuccessfully: 'Förderprogramm wurde erfolgreich gespeichert.',
    saveError: 'Das Förderprogramm konnte nicht gespeichert werden.',
    deleteFundingProgram: 'Förderprogramm löschen',
    deleteFundingProgramText: 'Möchtest du dieses Förderprogramm wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteFundingProgramSuccess: 'Förderprogramm wurde erfolgreich gelöscht.',
    deleteFundingProgramError: 'Das Förderprogramm konnte nicht gelöscht werden.',

    table: {
        name: 'Name',
        parent_funding_program_name: 'Übergeordnet',
        short_name: 'Kurzname',
        description: 'Beschreibung',
        is_active: 'Aktiv',
        created_at: 'Erstellt am'
    },

    menu: {
        fundingProgramBaseData: 'Daten',
        fundingProgramRights: 'Rechte',
        fundingProgramProcessFields: 'Vorgangsfelder'
    },

    rights
};