import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import val_de from './vee-validate/val_de.json'
import val_en from './vee-validate/val_en.json'
import zxcvbn from "zxcvbn";
import {electronicFormatIBAN, isValidIBAN} from 'ibantools';

const url_regex = '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$'; // fragment locator

const date_regex = '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$';

const rulesForErrorMessage = [
    'validation_pending',
    'puzzle_unsolved',
]

export default defineNuxtPlugin((nuxtApp) => {

    /**************
     * Custom rules
     **************/

    defineRule('verify_password', value => value && zxcvbn(value).score > 2);
    defineRule('confirm_password', (value, [password]) => value === password);
    defineRule('verify_url', value => new RegExp(url_regex, 'i').test(value));
    defineRule('verify_iban', value => {
        return isValidIBAN(electronicFormatIBAN(value));
    });
    defineRule('same', (value, [otherValue]) => value == otherValue);
    defineRule('required_if_greater', (value, [fieldValue, conditionValue]) => {
        if (fieldValue > conditionValue) {
            return value ? true : 'Dieses Feld muss ausgefüllt werden'
        }
        return true
    });
    defineRule('decimal', (value, [digits]) => {
        if (value.toString().indexOf(".") > -1 && value.toString().substring(value.toString().indexOf(".") + 1).length > digits) {
            return `Der Wert darf höchstens ${digits} Nachkommastelle` + (digits > 1 ? 'n' : '') + ` haben`
        } else {
            return true
        }
    });
    defineRule('currency', (value) => {
        if (value && value.toString().indexOf(".") > -1 && value.toString().substring(value.toString().indexOf(".") + 1).length > 2) {
            return `Der Wert darf höchstens 2 Nachkommastellen haben`
        } else {
            return true
        }
    });
    defineRule('date', value => {
        return (new RegExp(date_regex).test(value) ? true : "Das Datum muss im Format JJJJ-MM-TT sein")
    });

    defineRule('betweenDate', (value, {min, max}) => {
        if (!value || !min || !max) {
            return 'Start und Enddatum des Events werden für die Validierung benötigt';
        }
        // Convert the value, min, and max to Date objects
        const dateValue = new Date(value);
        const minDate = new Date(min);
        const maxDate = new Date(max);
        // Check if the value is between minDate and maxDate
        return dateValue >= minDate && dateValue <= maxDate ? true : "Das Datum muss zwischen dem Start und Enddatum des Events liegen";
    });

    defineRule('afterDate', (value, {pivot, name}) => {
        if (!value || !pivot) {
            return 'Aktuelles Datum und Referenzdatum des Events werden für die Validierung benötigt';
        }

        const dayjs = useDayjs()
        // Convert the value, min, and max to Date objects
        const dateValue = dayjs(value);
        const minDate = dayjs(pivot);

        // Check if the value is between minDate and maxDate
        return (dateValue.isSame(minDate, 'day') || dateValue.isAfter(minDate, 'day')) ? true : `Das Datum muss nach dem ${name} liegen`;
    });

    defineRule('afterTime', (value, [targetTime]) => {
        function isAfterTime() {
            // Split the time strings into hours and minutes
            const [valueHours, valueMinutes] = value.split(':').map(Number);
            const [targetHours, targetMinutes] = targetTime.split(':').map(Number);

            // Compare the hours
            if (valueHours > targetHours) {
                return true; // Value hours are greater, so it's definitely after
            } else if (valueHours < targetHours) {
                return false; // Value hours are less, so it's definitely before
            }

            // If the hours are the same, compare the minutes
            return valueMinutes > targetMinutes;
        }

        if (!isAfterTime()) {
            return 'Das Ende muss nach der Startzeit liegen'
        }
        return true
    });

    defineRule('overlappingTimeRanges', (value, {timeRanges, currentEntry}) => {
        const entryExists = timeRanges.some(entry => {
            return entry.startDate === currentEntry.startDate && entry.endDate === currentEntry.endDate;
        })
        if (!entryExists) {
            return  true;
        }
        const isOverlapping = timeRanges.some(entry => {
            // Exclude the current value from the check
            if (entry.startDate === currentEntry.startDate && entry.endDate === currentEntry.endDate) return false;

            return (currentEntry.startDate <= entry.endDate && currentEntry.endDate >= entry.startDate);
        })
        return isOverlapping ? 'Diese Zeitspanne überschneidet sich mit einer anderen.' : true;
    });

    defineRule('eventPersonErrors', value => {
        if (value > 0) {
            return "Es sind " + value + " Fehler bei den Personen aufgetreten"
        } else {
            return true
        }
    });

    defineRule('executedProgramsRequiredSum', (value, [field], ctx) => {
        if (field) {
            if (value >= ctx.form[field.fieldIdentifier]) return true
            return 'Die Summe aller Arbeitseinheiten muss mindestens dem Feld ' + field.label + ' entsprechen'
        }
        return true // in case there is no required sum
    })

    defineRule('editor_content_required', value => !(value === "" || value?.replaceAll(' ', '') === '<p></p>'));

    rulesForErrorMessage.forEach(rule => defineRule(rule, () => false))

    /**************
     * Configuration
     **************/

    Object.keys(AllRules).forEach(rule => {
        if (rule === 'all') return;
        defineRule(rule, AllRules[rule]);
    });

    configure({
        generateMessage: localize({
            en: {...en, messages: {...en.messages, ...val_en}}, de: {...de, messages: {...de.messages, ...val_de}}
        })
    });

    setLocale('de');

    nuxtApp.vueApp.component("VField", Field)
    nuxtApp.vueApp.component("VForm", Form)
    nuxtApp.vueApp.component("ErrorMessage", ErrorMessage)

    configure({
        classes: {
            valid: 'is-valid', invalid: 'is-invalid', dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
        }
    });
})