import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            const element = document.getElementById(to.hash.substring(1))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' } )
            } else {
                // report element not found
            }
        }
    }
}