import bankAccounts from './bankAccounts'
import info from './info'
import menu from './menu'
import table from './table'
import exportLabels from './exportLabels'

export default {
    organisationId: 'Organisation {id}',
    organisationGeneral: 'Allgemein',
    organisationAddress: 'Adresse',
    organisationContactPerson: 'Ansprechpartner',
    organisationAcceptTransferAgreement: 'Weitergabevertrag akzeptiert',
    organisationConnections: 'Verknüpfungen',
    organisationBankAccount: 'Bankverbindung',
    organisationUsers: 'Nutzer',

    membersCount: 'Mitglieder',

    name: 'Name',
    parent_organisation_id: 'Übergeordnete Organisation',
    searchParentOrganisation: 'Suche nach Name, PLZ, Ort',
    carrier_type_id: 'Art des Trägers',
    carrier_legal_form_id: 'Rechtsform des Trägers',
    parish_number: 'Pfarrbezirksnummer',
    active: 'Aktiv',
    street: 'Straße',
    house_number: 'Hausnummer',
    zipcode: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',
    ags: 'Gemeindeschlüssel',
    contact_person_firstname: 'Vorname',
    contact_person_lastname: 'Nachname',
    contact_person_email: 'E-Mail',
    contact_person_phone: 'Telefon',
    organisation_id: 'Organisation',
    user_id: 'Nutzer',
    role_name: 'Rolle',

    category_id: 'Kategorie Id',
    tags: 'Tags',

    form: {
        general: {
            name: 'Name',
            parentOrganisation: 'Übergeordnete Organisation',
            noParentOrganisation: 'Keine übergeordnete Organisation',
            searchParentOrganisation: 'Suche nach Name, PLZ, Ort',
            type: 'Organisationstyp',
            types: {
                organisation: 'Standardorganisation',
                association: 'Verband',
                rp: 'Regierungspräsidium'
            },
            carrierType: 'Art des Trägers',
            carrierTypePlaceholder: 'Bitte wählen...',
            carrierLegalForm: 'Rechtsform des Trägers',
            carrierLegalFormPlaceholder: 'Bitte wählen...',
            parishNumber: 'Pfarrbezirksnummer',
            active: 'Aktiv'
        },

        address: {
            street: 'Straße',
            houseNumber: 'Hausnummer',
            zipcode: 'Postleitzahl',
            city: 'Stadt',
            country: 'Land',
            foreignAddress: 'Ausländische Adresse',
            ags: 'Gemeindeschlüssel',
            agsAutoFilled: 'Der Gemeindeschlüssel wird automatisch generiert.'
        },

        contactPerson: {
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail',
            phone: 'Telefon'
        },

        transferAgreement: {
            useTransferAgreement: 'Weitergabevertrag nutzen',
        }
    },

    saveSuccess: 'Organisation wurde erfolgreich gespeichert.',

    deleteOrganisation: 'Organisation löschen',
    deleteOrganisationText:
        'Möchtest du die Organisation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrganisationSuccess: 'Organisation wurde erfolgreich gelöscht.',
    deleteOrganisationError: 'Die Organisation konnte nicht gelöscht werden.',

    toggleState: 'aktivieren / deaktivieren',
    toggleStateSuccess: 'Status wurde erfolgreich geändert.',
    toggleStateError: 'Der Status konnte nicht geändert werden.',

    print: {
        noDocumentCouldBeExported: 'Es konnte kein Dokument zur Ansicht gefunden oder erzeugt werden.',
        exportError: 'Fehlende Vorlage',
    },

    manuallyAcceptTransferAgreement: {
        success: 'Der Weitergabevertrag wurde als unterschrieben markiert.',
        error: 'Der Weitergabevertrag konnte nicht als unterschrieben markiert werden.',
    },

    acceptTransferAgreement: {
        scanQrCode: 'Scanne den QR-Code um den Weitergabevertrag zu unterschreiben.',
        missingScanningDevice: 'Kein Gerät zum einscannen?',
        signOnThisDevice: 'Auf diesem Gerät unterschreiben'
    },

    transfer_agreement_signature_title: 'Unterschrift Weitergabevertrag',
    transfer_agreement_signature_description: 'Hiermit bestätigst du, dass du den Weitergabevertrag gelesen hast und diesen akzeptierst.',

    bankAccounts,
    info,
    menu,
    table,
    exportLabels
}