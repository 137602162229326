export default {
    title: 'Kirchenbezirke',
    name: 'Bezeichnung',
    reference_number: 'Kennnummer',
    customName: 'Bezeichnung',
    parent_region_id: 'LK',
    token: 'Token',
    members_all: 'Glieder',
    members_youth: 'Glieder (6-26)',
    average_age: 'Alter ø',
    location: 'Prälatur',
    updated_at: 'Geändert am'
}