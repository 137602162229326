export default {
    title: 'Projekttage',
    date: 'Datum',
    dayOfWeek: 'Wochentag',
    addDay: 'Neuen Tag hinzufügen',
    listing: {
        start: 'Start',
        end: 'Ende',
        duration: 'Dauer',
        totalDuration: 'Gesamtdauer',
    },
    dateAlreadyExists: 'Datum existiert bereits',
    noMoreDatesAvailable: 'Es gibt kein weiteres verfügbares Datum zwischen dem Start- und Enddatum des Events.',
    changeCausedErrors: 'Durch die Aktion sind Fehler im Programmnachweis entstanden.',
}