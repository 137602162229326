import event from './event.js'
import eventPersons from './eventPersons.js'
import executedPrograms from './executedPrograms.js'
import projectDays from "./projectDays.js"

export default {
    organisation_id: 'Organisation',
    creator_user_id: 'Ersteller',
    process_id: 'Vorgang',
    year_id: 'Jahr',
    bankAccount: 'Bankverbindung',
    bank_account_id: 'Bankverbindung',
    formable_id: 'Formular',
    formable_type: 'Formulartyp',
    status_id: 'Status',
    data: 'Formulardaten',
    sign: 'Unterschrift',

    event,
    eventPersons,
    executedPrograms,
    projectDays,

    documents: {
        delete: 'Löschen',
        deleteDocument: 'Dokument löschen',
        deleteDocumentText: 'Möchtest du dieses Dokument wirklich löschen?'
    },

    signature: {
        title: 'Unterschrift',
        signature: 'Unterschrift',
        signFormProcess: 'Antrag unterschreiben',
        information: 'Unterschreibe den Antrag erst, wenn das Formular vollständig ausgefüllt ist und abgeschickt werden soll.',
        unknownUser: 'Unbekannter Nutzer',
        scanQrCode: 'Scanne den QR-Code, um den Antrag auf deinem Smartphone zu unterschreiben.',
        missingScanningDevice: 'Kein Gerät zum einscannen?',
        signOnThisDevice: 'Auf diesem Gerät unterschreiben.'
    },

    formLocked: 'Das Formular ist gesperrt.',
    formLockedText: 'Das Formular wurde unterschrieben und kann nicht weiter bearbeitet werden.',
    removeSignature: 'Unterschrift entfernen',

    submit: 'Absenden',
    saveDraft: 'Entwurf speichern',
    createSuccess: 'Das Formular wurde erfolgreich angelegt.',
    createError: 'Das Formular konnte nicht angelegt werden.',
    saveSuccess: 'Das Formular wurde erfolgreich gespeichert.',
    saveError: 'Das Formular konnte nicht gespeichert werden.',

    formNavigator: {
        newFormProcess: 'Neues Formular',
        newProcess: 'Neuer Vorgang',
        saveDraft: 'Entwurf speichern'
    },

    print: {
        title: 'PDF erzeugen',
        descriptionFormProcess: 'Für wen soll die PDF vom Formular {formIdentifier} erzeugt werden?',
        descriptionProcess: 'Für wen soll die PDF vom Vorgang {vNumber} erzeugt werden?',

        printForOptions: {
            applicant: 'Zuschussbeantragende',
            manager: 'Zuschussverwaltende',
            donor: 'Zuschussgebende',
        },

        printSuccess: 'Die PDF wurde erfolgreich erzeugt.',
        printError: 'Die PDF konnte nicht erzeugt werden.'
    },

    deleteFormProcess: 'Formular löschen',
    deleteFormProcessText: 'Möchtest du dieses Formular wirklich löschen?',
    deleteFormProcessTextLastForm: 'Der Vorgang wird bei dieser Aktion ebenfalls gelöscht.',
    deleteFormProcessSuccess: 'Formular wurde erfolgreich gelöscht.',
    deleteFormProcessError: 'Das Formular konnte nicht gelöscht werden.',
}