export default {
    name: 'Name',
    identifier: 'Kürzel',
    identifierHelpTextNew: 'Das Kürzel muss eindeutig sein. Es darf sich in keinem anderen Formular wiederholen.',
    identifierHelpTextExisting: 'Das Kürzel sollte nur in Rücksprache mit den Entwicklern von //farbcode geändert werden.',
    fundingProgram: 'Förderprogramm',
    type: 'Typ',
    types: {
        application: 'Antrag', proof: 'Verwendungsnachweis'
    },
    sort: 'Sortierung',
    isActive: 'Aktiv',
    signature_required: 'Unterschrift benötigt',
    event_card: 'Veranstaltungskarte',
    persons_card: 'Personenkarte',
    formFieldConfig: 'Formularfelderkonfiguration',

    uploadBoxConfig: 'Uploadbereichkonfiguration',
    visibilitySectionHelpText: 'Die Sichtbarkeit der Boxen hängt an den bei dem zugehörigen Förderprogramm hinterlegten Rechten',
    visibilitySection: {
        personsData: 'Personendaten',
        eventData: 'Veranstaltungsdaten',
        ownFiles: 'Nur für eigene Organisation sichtbar',
        alwaysVisible: 'Immer sichtbar',
    },
    visibleIfHelpText: 'Hier wird die Sichtbarkeit der Boxen beim Beantragen des Formulars gesteuert. Die Sichtbarkeit ' +
        'der Boxen kann sich hier während dem Ausfüllen des Formulars ändern. Wird verwendet bei TN-Listen oder Programmnachweisen.',

    editEventPersonCustomField: 'Formularspezifisches Personenfeld {name} bearbeiten',
    saveEventPersonCustomField: 'Formularspezifisches Personenfeld speichern',
    deleteEventPersonCustomField: 'Formularspezifisches Personenfeld löschen',
    deleteEventPersonCustomFieldText: 'Möchtest du dieses formularspezifische Personenfeld wirklich löschen?',

    saveSuccess: 'Formular wurde erfolgreich gespeichert.',
    deleteForm: 'Formular löschen',
    deleteFormText: 'Möchtest du dieses Formular wirklich löschen?',
    deleteFormSuccess: 'Formular wurde erfolgreich gelöscht.',
    deleteFormError: 'Das Formular konnte nicht gelöscht werden.',

    table: {
        id: 'ID',
        name: 'Name',
        identifier: 'Kürzel',
        fundingProgram: 'Förderprogramm',
        is_active: 'Aktiv',
        sort: 'Sortierung',

        qf_activeOnly: 'Nur aktive Formulare'
    },

    menu: {
        formBaseData: 'Daten',
        configuration: 'Konfiguration',
        formClass: 'Formularklasse',
        exportTemplate: 'PDF Vorlage',
        eventPersonCustomFields: 'Formularspezifische Personenfelder'
    }
}