export default {
    qf_formProcessStatusOnly: 'Formularstatus',
    qf_processStatusOnly: 'Vorgangsstatus',
    qf_signedOnly: 'Unterschrift',
    qf_eventOnly: 'Veranstaltung',
    qf_eventPersonOnly: 'Personen',
    qf_executedProgramOnly: 'Programmnachweis',
    qf_formProcessOnly: 'Formular',
    qf_processOnly: 'Vorgang',
    qf_documentsOnly: 'Dokumente'
}