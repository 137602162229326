import menu from './menu'
import table from './table'

export default {
    status: 'Status',
    key: 'Schlüsselwort',
    name_management_area: 'Name Verwaltungsbereich',
    name_application_area: 'Name Antragsbereich',
    color: 'Farbe',
    model_class: 'Gehört zu',
    sort: 'Sortierung',
    transitions: 'Übergänge',
    set_process_status_id: 'Vorgangsstatus',
    form_data_complete_validation: 'Formularfelder prüfen',
    edit_application_area: 'Felder im Antragsbereich bearbeitbar',

    model_classes: {
        FormProcess: 'Formular',
        Process: 'Vorgang'
    },

    colors: {
        gray: 'Grau',
        red: 'Rot',
        yellow: 'Gelb',
        green: 'Grün',
        blue: 'Blau',
        indigo: 'Indigo',
        purple: 'Lila',
        pink: 'Pink'
    },

    saveSuccess: 'Status wurde erfolgreich gespeichert.',
    deleteStatus: 'Status löschen',
    deleteStatusText: 'Möchtest du diesen Status wirklich löschen?',
    deleteStatusSuccess: 'Status wurde erfolgreich gelöscht.',
    deleteStatusError: 'Der Status konnte nicht gelöscht werden.',

    menu,
    table,
}