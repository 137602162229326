export default {
    name_management_area: 'Name Verwaltungsbereich',
    name_application_area: 'Name Antragsbereich',
    model_class: 'Gehört zu',
    sort: 'Sortierung',

    model_classes: {
        FormProcess: 'Formular',
        Process: 'Vorgang'
    },

    qf_modelClassProcessOnly: 'Nur Vorgangsstatus',
    qf_modelClassFormProcessOnly: 'Nur Formularstatus'
}