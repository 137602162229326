export default {
    title: 'Personen',

    function: 'Funktion',
    gender: 'Geschlecht',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße',
    house_number: 'Hausnummer',
    zipcode: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',
    participation_begin: 'Beginn der Teilnahme',
    participation_end: 'Ende der Teilnahme',
    participation_duration: 'Dauer der Teilnahme',

    numberPersons: 'Personen gesamt',
    numberSelectedPersons: 'Anzahl ausgewählter Personen',

    functions: {
        leader: 'Leitung',
        leaderShort: 'LT',
        staff: 'Mitarbeitende*r',
        staffShort: 'MA',
        participant: 'Teilnehmende*r',
        participantShort: 'TN'
    },

    genders: {
        male: 'Männlich',
        female: 'Weiblich',
        diverse: 'Divers',
        other: 'Keine Angabe'
    },

    addNewEventPerson: 'Neue Person hinzufügen',
    editEventPerson: 'Person bearbeiten',
    saveEventPerson: 'Person speichern',
    deleteEventPerson: 'Person löschen',
    deleteEventPersonText: 'Möchtest du diese Person wirklich löschen?',
    deleteEventPersonNameText: 'Möchtest du {name} wirklich löschen?',

    successfullySaved: 'Person wurde erfolgreich gespeichert.',
    couldNotBeSaved: 'Die Person konnte nicht gespeichert werden.',
    successfullyDeleted: 'Person wurde erfolgreich gelöscht.',
    couldNotBeDeleted: 'Die Person konnte nicht gelöscht werden.',

    import: {
        titleExcel: 'Personen aus Excel-Datei importieren',
        descriptionExcel: 'Importiere Personen aus einer Excel-Datei.',
        titleAmosWeb: 'Personen aus amosWEB importieren',
        descriptionAmosWeb: 'Importiere Personen aus amosWEB.',

        amosWebEvent: 'amosWEB-Veranstaltung',

        importFile: 'Importdatei',
        import: 'Importieren',

        importSuccess: 'Import erfolgreich',
        importStarted: 'Import wurde gestartet',
        importFailure: 'Import fehlgeschlagen'
    }
}