export default {
    title: 'Veranstaltung',
    name: 'Titel',
    location: 'Ort',
    date: 'Datum',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    duration: 'Dauer',
    durationDays: '{count} Tage|{count} Tag|{count} Tage',
    address: 'Adresse',
    street: 'Straße',
    house_number: 'Hausnummer',
    zipcode: 'Postleitzahl',
    city: 'Stadt',

    editEvent: 'Veranstaltung bearbeiten',
    saveEvent: 'Veranstaltung speichern',
    successfullySaved: 'Veranstaltung wurde erfolgreich gespeichert.',
    couldNotBeSaved: 'Die Veranstaltung konnte nicht gespeichert werden.'
}