export default {
    title: 'Organisations',
    name: 'Name',
    email: 'E-Mail',
    created_at: 'Created at',
    active: 'State',
    isActive: 'Active',
    isInactive: 'Inactive',
    id: 'ID'
}
