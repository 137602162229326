export default {
    title: "Erstelle Zahlungsläufe",
    generatePaymentRuns: "Erstellen",
    generatePreview: "Vorschau",
    bookingDate: "Buchungsdatum",
    bankAccount: "Bankverbindung",
    percentage: "Prozentsatz",
    intendedUse: "Verwendungszweck",
    intendedUsePlaceholders: "Sie können im Verwendungszweck mehrere Platzhalter verwenden.",
    asPreview: "Nur Vorschau PDF erzeugen",
    comment: "Interner Kommentar",
    paymentRunsGenerated: "Zahlungsläufe wurden erstellt.",
    noValidPaymentRuns: "Die aktuelle Auswahl erzeugt keine gültigen Zahlungsläufe!",
    noBankAccountsToUse: "Es sind keine Bankkonten für die Zahlungsläufe verfügbar.",
    generatedRunsTitle: "Es entstehen folgende Zahlungsläufe:",
    errorOnCreation: "Es ist ein Fehler beim Erstellen der Zahlungsläufe aufgetreten."
}