export default {
    id: 'Jahr',
    is_active: 'Aktiv',
    is_active_help_text: 'Aktiv bedeutet, dass das Jahr standardmäßig bei allen Seiten ausgewählt ist. Es kann nur ein Jahr aktiv sein.',
    is_visible: 'Sichtbar',
    is_visible_help_text: 'Sichtbar bedeutet, dass das Jahr in den Jahres-Auswahlboxen angezeigt wird.',

    saveSuccess: 'Jahr wurde erfolgreich gespeichert.',
    deleteYear: 'Jahr löschen',
    deleteYearText: 'Möchtest du dieses Jahr wirklich löschen?',
    deleteYearSuccess: 'Jahr wurde erfolgreich gelöscht.',
    deleteYearError: 'Das Jahr konnte nicht gelöscht werden.',

    menu: {
        yearBaseData: 'Grunddaten',
        yearData: 'Jahresdaten',
    },

    table: {
        id: 'Jahr',
        is_active: 'Aktiv',
        is_visible: 'Sichtbar',
    }
}