export default {
    association_name: 'Name des Verbands',
    association_street: 'Straße des Verbands',
    association_house_number: 'Hausnummer des Verbands',
    association_zipcode: 'Postleitzahl des Verbands',
    association_city: 'Stadt des Verbands',

    organisation_name: 'Name der Organisation',
    organisation_contact_person_fullname: 'Vor- und Nachname des Ansprechpartners der Organisation',
    organisation_street: 'Straße der Organisation',
    organisation_house_number: 'Hausnummer der Organisation',
    organisation_zipcode: 'Postleitzahl der Organisation',
    organisation_city: 'Stadt der Organisation',
    organisation_signature: 'Unterschrift der Organisation',
    organisation_signature_date: 'Datum der Unterschrift der Organisation',
    organisation_signer: 'Unterschreibender der Organisation',

}