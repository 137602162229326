export default {
    importType: 'Import Format',
    importTypes: {
        regions: 'Kirchenbezirke',
        parishes: 'Kirchengemeinden (als Hintergrundprozess, empfohlen)',
        parishesSync: 'Kirchengemeinden',
        diaconia: 'Diakonie Daten',
        activities: 'Aktivitäten'
    },
    importFile: 'Import Datei',
    import: 'Importieren',
    importHeader: 'Jugen zählt 2 - Daten Import',
    importDescription: 'Importieren Sie Daten zu Jugend zählt 2',
    importSuccess: 'Import erfolgreich',
    importStarted: 'Import wurde gestartet',
    importFailure: 'Import fehlgeschlagen',

    tokenRequired: 'Um die Daten zu „Jugend zählt“ einsehen zu können, brauchen Sie den zugehörigen Token.',

    notEnoughData: 'Zellenbesetzungen kleiner 5 werden aus Datenschutzgründen nicht berichtet.',
    abbreviationMeaning: 'Die Abkürzung MA steht für Mitarbeitende (bei Gruppen) bzw. Mitarbeitenden-Engagements (bei Einzelangeboten). Die Abkürzung TN steht für Teilnehmende (bei Gruppen) bzw. Teilnahmen (bei Einzelangeboten). Vgl. dazu das Kapitel 2 im Buch „Jugend zählt 2“.',
    demographicInfo: 'Alle Daten zur evangelischen Demografie wurden zum Stand 31.12.2022 aus dem kirchlichen Meldewesen entnommen',
    generalInfoOne: 'Alle Daten zu den Aktivitäten berichten die Ergebnisse der Erhebung „Jugend zählt 2“ für den Bezugszeitraum 2021/22 inklusive hochgerechneter Zahlen für nicht meldende Kirchengemeinden. Der Rücklauf bei „Jugend zählt 2“ lag bei 72%, für die Jugendverbände konnte keine Hochrechnung durchgeführt werden. Die realen Daten können sich mittlerweile geändert haben. Es kann kein Anspruch auf Perfektion bestehen, da die Zahlen nur so gut sind wie die Meldungen waren. Bitte beachten Sie die methodischen Hinweise im Buch „Jugend zählt 2“, das unter ',
    generalInfoTwo: 'kostenfrei zur Verfügung steht.',
    diaconicData: 'Die diakonischen Daten in "Jugend zählt 2" wurden nicht hochgerechnet, weil hier fast eine Vollerhebung möglich war. Die Angeboten der Behindertenhilfe werden hier aggregiert dargestellt. Bitte beachten Sie die Hinweise in den Kapiteln 3 und 20 im Buch "Jugend zählt 2", insb. S. 271 (Kinder- und Jugendhilfe), S. 300 (Behindertenhilfe) und S. 276 (Vollzeitäquivalente).',

    descriptionOne: 'Nach der Erhebung „Jugend zählt 1“ im Zeitraum 2012/13 (Ilg / Heinzmann / Cares 2014) erfolgte für den Bezugszeitraum 2021/22 eine zweite, weitgehend identische Erhebung statistischer Daten zur kirchlichen Arbeit mit Kindern und Jugendlichen in den Evangelischen Landeskirchen in Baden und Württemberg, ergänzt um Daten aus der Diakonie und den evangelischen Freiwilligendiensten. ',

    activities: {
        amount: 'Anzahl',
        participants: 'Teilnehmende',
        caregivers: 'Mitarbeitende',
        average_amount: 'Anzahl (Durchschnitt Landeskirche)',
        average_participants: 'Teilnehmende (Durchschnitt Landeskirche)',
        average_caregivers: 'Mitarbeitende (Durchschnitt Landeskirche)'
    },

    diaconias: {
        reached_youth: 'Erreichte junge Menschen',
        full_time_equivalency: 'Vollzeitäquivalente',
        average_reached_youth: 'Erreichte junge Menschen (Durchschnitt Landeskirche)',
        average_full_time_equivalency: 'Vollzeitäquivalente (Durchschnitt Landeskirche)'
    }
}