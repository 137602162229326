import tools from './tools/main'
import filter from './filter/main'

export default {
    overview: 'Übersicht',
    totalEntries: 'Keine Ergebnisse |  Ein Eintrag wurde gefunden | Insgesamt wurden {count} Einträge gefunden',
    labelPerPageOptions: 'Einträge pro Seite',
    createdAt: 'Erstellt',
    updatedAt: 'Zuletzt bearbeitet',

    actions: {
        filters: 'Filter',
        create: 'Erstellen',
        edit: 'Bearbeiten',
        print: 'PDF',
        tools: 'Werkzeuge'
    },

    tools,
    filter
}